import AdyenCheckout from "@adyen/adyen-web";
import { useState, useRef, useEffect } from "react";
import { axiosInstance } from '../services/request';

export const BookingPage = () => {
  const [paymentResultCode, setPaymentResultCode] = useState("");

  const paymentContainer = useRef(null);

  useEffect(() => {
    if (paymentResultCode) {
      return;
    }
    if (window.location.search.indexOf("?sessionId=") !== -1) {
      submitCheckoutResult();
    } else if (window.location.search.indexOf("?ref=") !== -1) {
      setupCheckout();
    }
  }, []);

  const submitCheckoutResult = async () => {
    const urlParams = new URLSearchParams(window.location.search);

    //Create an instance of AdyenCheckout to handle the shopper returning to your website.
    // Configure the instance with the sessionId you extracted from the returnUrl.

    const config = {
      environment: "test", // Change to 'live' for the live environment.
      clientKey: "test_DWHPZIJYZVBAVFCS7UJHH275W4T6XMEW", // Public key used for client-side authentication: https://docs.adyen.com development-resources/client-side-authentication
      analytics: {
        enabled: true, // Set to false to not send analytics data to Adyen.
      },
      session: {
        id: urlParams.get("sessionId"),
        sessionData: localStorage.getItem("ps"),
      },
      onPaymentCompleted: (result, component) => {
        setPaymentResultCode(result.resultCode);
        // const token = localStorage.getItem("token")
        // axiosInstance.get('/secure/bookings/confirmAdyenResult', { headers: { 'Authorization': `Basic ${token}` } }).then((response) => {
        //   console.log(response.data);
        // }).catch((error) => { console.error(error.toJSON()); })
      },
      onError: (error, component) => {
        console.log(error);
      },
    };

    const checkout = await AdyenCheckout(config);
    // Submit the redirectResult value you extracted from the returnUrl.
    checkout.submitDetails({ details: { redirectResult: urlParams.get("redirectResult") } });
  };

  const extractUrlParams = () => {
    let params = [];

    window.location.search.split("&").forEach((param) => {
      const key = param.split("=")[0].replace("?", "");
      const value = param.split("=")[1];
      params[key] = value;
    });

    return params;
  };

  const setupCheckout = async () => {
    const urlParams = extractUrlParams();
    localStorage.setItem("ps", urlParams.paymentSession);
    localStorage.setItem("token", urlParams.token)

    const config = {
      environment: "test", // Change to 'live' for the live environment.
      clientKey: "test_DWHPZIJYZVBAVFCS7UJHH275W4T6XMEW", // Public key used for client-side authentication: https://docs.adyen.com development-resources/client-side-authentication
      analytics: {
        enabled: true, // Set to false to not send analytics data to Adyen.
      },
      paymentMethodsConfiguration: {
        card: {
          showStoredPaymentMethods: true,
          enableStoreDetails: true,
        }
        
      },
      session: {
        id: urlParams.ref,
        sessionData: urlParams.paymentSession,
      },
      onPaymentCompleted: (result, component) => {
        console.info("Adyen result: " + result.resultCode);
        console.info("Adyen session data: " + result.sessionData)
      },
      onError: (error, component) => {
        console.log(error);
      },
    };
    const checkout = await AdyenCheckout(config);
    if (paymentContainer.current) {
      const dropinComponent = checkout.create("dropin").mount(paymentContainer.current);
    }
  };

  const renderSuccess = () => {
    return (
      <div>
        <h2>Payment complete!</h2>
        <p>You can now close this window</p>
      </div>
    );
  };

  if (paymentResultCode) {
    if (paymentResultCode === "Authorised" || paymentResultCode === "Authorized") {
      return (
        <div>
          <h2>Payment complete!</h2>
          <p>You can now close this window</p>
        </div>
      );
    } else if (paymentResultCode == "Refused") {
      return (
        <div>
          <h2>Payment refused!</h2>
          <p>Please try again</p>
        </div>
      );
    }
  } else {
    return (
      <div>
        <div ref={paymentContainer} id="dropin-container"></div>
      </div>
    );
  }
};
