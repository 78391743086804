import { render } from "react-dom";

import { BookingPage } from "./pages/booking";

import "@adyen/adyen-web/dist/adyen.css";

render(
  <div>
    <BookingPage />
  </div>,
  document.getElementById("app"),
);
